import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class AuthService {
  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  login(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  verifyAuthyCode(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.verifyAuthyCodeEndpoint, ...args)
  }

  resetPassword(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.resetPassword, ...args)
  }

  recoveryPassword(args) {
    return axiosService.axiosIns.post(this.jwtConfig.recoveryPassword, { ...args })
  }

  forgotPassword(...args) {
    return axiosService.axiosIns.post(this.jwtConfig.forgotPassword, ...args)
  }
}

function useAuthService() {
  const authService = new AuthService()

  return {
    authService,
  }
}

const { authService } = useAuthService()
export default authService
